<template>
    <div 
        class="modal fade" :id="'employeerShortInfo' + this.$props.employeerInfo.id" 
        tabindex="-1" aria-labelledby="Краткая информация о партнере" aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content rounded-1 p-0">
                <div class="modal-header d-flex align-items-center border-0 p-20">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
                        Сотрудник партнёра
                    </span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                    ></button>
                </div>

                <div class="modal-body pt-0 px-20">
                    <div class="">
                        <div class="mb-2">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Ф.И.О::</span>
                            <span>{{this.$props.employeerInfo.name}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Эл. почта:</span>
                            <span>{{this.$props.employeerInfo.email}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Телефон:</span>
                            <span>{{this.$props.employeerInfo.phone}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Должность:</span>
                            <span>{{this.$props.employeerInfo.rank}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">День рождения:</span>
                            <span>{{this.$props.employeerInfo.birthDay}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            employeerInfo: { type: Object }
        }
    }
</script>