<template>
   <ul class="m-0 p-0">
        <li
            v-for="(partner, i) of this.$props.partnerList"
            :key="'partner_' + i"
            class="border border-brd-primary rounded-1 bg-white pb-0 mb-20"
        >
            <PartnerCard 
                :partnerData="partner"
            />
        </li>
   </ul>
   <!--<Pagination :itemsList="[]"/>-->
</template>

<script>
    import PartnerCard from '@/components/partners/PartnerCard'
    // import Pagination from '@/components/common/Pagination'

    export default {
        name: 'PartnerList',

        components: {
            PartnerCard,
            // Pagination
        },

        props: {
            partnerList: { type: Array },
        }
    }
</script>