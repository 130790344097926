<template>
    <ul class="m-0 p-0">
        <li
            v-for="(emp, i) of this.$props.employees"
            :key="'emp_' + i"
            class="border border-brd-primary rounded-1 bg-white p-20"
            :class="i !== this.$props.employees.length - 1 ? 'mb-10' : null"
        >
            <EmployeerShortCard 
                :employeer="emp"
            />
        </li>
    </ul>
 </template>
 
 <script>
     import EmployeerShortCard from '@/components/employees/EmployeerShortCard'
 
     export default {
         name: 'EmployeeShortList',
 
         components: {
            EmployeerShortCard
         },
 
         props: {
            employees: { type: Array },
         }
     }
 </script>