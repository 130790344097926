<template>
    <div 
        class="modal fade" :id="'partnerShortInfo' + this.$props.partnerInfo.id" 
        tabindex="-1" aria-labelledby="Краткая информация о партнере" aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content rounded-1 p-0">
                <div class="modal-header d-flex align-items-center border-0 p-20">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
                        Компания-партнёр
                    </span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                    ></button>
                </div>

                <div class="modal-body pt-0 px-20">
                    <div class="border-bottom border-brd-primary mb-20">
                        <div class="mb-2">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Наименование:</span>
                            <span>{{this.$props.partnerInfo.title}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Адрес отделения:</span>
                            <span>{{this.$props.partnerInfo.address}}</span>
                        </div>

                        <div class="mb-3">
                            <span class="d-block text-font-secondary d-block mb-1 fs-5">Жилой комплекс:</span>
                            <span>{{this.$props.partnerInfo.estate}}</span>
                        </div>
                    </div>

                    <div class="">
                        <span class="d-block mb-2">Руководитель</span>

                        <div class="">
                            <div class="mb-2">
                                <span class="d-block text-font-secondary d-block mb-1 fs-5">Ф.И.О::</span>
                                <span>{{this.$props.partnerInfo.name}}</span>
                            </div>
    
                            <div class="mb-3">
                                <span class="d-block text-font-secondary d-block mb-1 fs-5">Эл. почта:</span>
                                <span>{{this.$props.partnerInfo.email}}</span>
                            </div>
    
                            <div class="mb-3">
                                <span class="d-block text-font-secondary d-block mb-1 fs-5">Телефон:</span>
                                <span>{{this.$props.partnerInfo.phone}}</span>
                            </div>

                            <div class="mb-3">
                                <span class="d-block text-font-secondary d-block mb-1 fs-5">Должность:</span>
                                <span>{{this.$props.partnerInfo.rank}}</span>
                            </div>

                            <div class="mb-3">
                                <span class="d-block text-font-secondary d-block mb-1 fs-5">День рождения:</span>
                                <span>{{this.$props.partnerInfo.birthDay}}</span>
                            </div>

                            <div>
                                <span class="d-block text-font-secondary d-block mb-3 fs-5">Документы:</span>
                                <div class="d-flex align-items-center">
                                    <img :src="require('@/assets/icons/blank-icon.svg')" alt="Документ" width="18" height="18" class="me-10">
        
                                    <span class="me-10 fs-4">КП Сокращенное.docx</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            partnerInfo: { type: Object }
        }
    }
</script>