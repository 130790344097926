<template>
    <div>
        <!--шапка-->
        <div class="p-20 pb-0">
            <div class="d-flex align-items-start border-bottom border-brd-primary">
                <div class="me-auto " style="max-width: 80%;">
                    <div class="d-flex flex-wrap mb-20">
                        <span class="text-success me-20 fs-5">{{this.$props.partnerData.status}}</span>
                        <span class="text-secondary me-20 fs-5">Добавлен: {{this.$props.partnerData.published_at}}</span>
                    </div>

                    <div class=" d-flex align-items-center mb-20">

                        <button 
                            type="button" 
                            class="
                                    d-flex align-items-center btn bg-light-gray 
                                    text-secondary border border-brd-primary rounded-1 
                                    font-semi fw-semi position-relative p-10 px-3 me-10
                                    fs-5 text-font-secondary
                                "
                        >
                            <img :src="require('@/assets/icons/note-icon.svg')" alt="Настройка" width="14" height="14" class="me-1">
                            {{ this.$props.partnerData.estate }}
                        </button>

                        <button
                            v-if="!this.$props.partnerData.isActive"
                            type="button" 
                            class="
                                    btn btn-outline-raspberry rounded-1
                                    fs-5 font-semi fw-semi p-10 px-3
                                "
                        >
                            Отключен
                        </button>
                    </div>

                    <div class="d-flex flex-column mb-3">

                        <span 
                            class="crm-underline fs-3 fw-semi font-semi" 
                            style="max-width: 300px; cursor: pointer;"
                            data-bs-toggle="modal" :data-bs-target="'#partnerShortInfo' + this.$props.partnerData.id"
                        >
                            {{this.$props.partnerData.name}}
                        </span>
                        <span
                            class="fst-italic text-font-secondary fs-4 mb-1"
                        >
                            {{this.$props.partnerData.address}}
                        </span>

                        <span class="fs-4 fst-italic text-font-primary crm-underline" @click="isInfoOpened = !isInfoOpened" style="cursor: pointer;">{{isInfoOpened ? 'Свернуть' : 'Подробнее' }}</span>
                    </div>
                </div>

                <div class="d-flex flex-column">
                    <button 
                        type="button" 
                        class="btn mb-2"
                        data-bs-toggle="modal" :data-bs-target="'#editPartner' + this.$props.partnerData.id"
                    > 
                        <img :src="require('@/assets/icons/options-icon.svg')" alt="Настройка" width="24" height="24">
                    </button>
                    
                    <button 
                        type="button" class="btn mb-2"
                        data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.partnerData.id"
                        @click="fillConfirmModalState('deletePartner')"
                    >
                        <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                    </button>

                    <button
                        v-if="!this.$props.partnerData.isActive"
                        type="button" class="btn mb-2"
                        data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.partnerData.id"
                        @click="fillConfirmModalState('turnPartnerOn')"
                    >
                        <img :src="require('@/assets/icons/turnOff-red-icon.svg')" alt="Отключить" width="24" height="24">
                    </button>

                    <button
                        v-else
                        type="button" class="btn mb-2"
                        data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.partnerData.id"
                        @click="fillConfirmModalState('turnPartnerOff')"
                    >
                        <img :src="require('@/assets/icons/turnOff-icon.svg')" alt="Отключить" width="24" height="24">
                    </button>
                </div>
            </div>
        </div>
        <!--шапка конец-->
        <!--контент-->
        <div 
            v-if="isInfoOpened"
            class="p-20 pb-0"
        >
            <div class="border-bottom border-brd-primary">
                <div class="d-flex align-items-center mb-3">
                    <div class="d-flex flex-column w-50">
                        <span class="fs-5 text-font-secondary">Клиенты</span>
                        <a 
                            href="javascript:{}"
                            class="text-black active"
                            :class="'active' ? 'crm-underline' : null"
                        >
                            {{this.$props.partnerData.clients.length}}
                        </a>
                    </div>

                    <div class="d-flex flex-column w-50">
                        <span class="fs-5 text-font-secondary">Сотрудники</span>
                        <a 
                            href="javascript:{}"
                            class="text-black"
                        >
                            {{this.$props.partnerData.staffList.length}}
                        </a>
                    </div>
                </div>

                <div class="d-flex flex-column mb-3">
                    <span class="fs-5 text-font-secondary mb-1">Руководитель</span>
                    <span class="mb-1">{{this.$props.partnerData.manager}}</span>
                    <span class="fs-5 text-font-secondary fst-italic">Руководитель ОП</span>
                </div>

                <div class="d-flex flex-column mb-3">
                    <span class="fs-5 text-font-secondary mb-1">Телефон</span>
                    <span>{{this.$props.partnerData.phone}}</span>
                </div>
                <div class="d-flex flex-column mb-3">
                    <span class="fs-5 text-font-secondary mb-1">Эл. почта</span>
                    <a :href="'mailto:' + this.$props.partnerData.email" class="text-font-primary crm-underline">{{this.$props.partnerData.email}}</a>
                </div>
                <div class="d-flex flex-column">
                    <span class="fs-5 text-font-secondary mb-10">Соц. сети</span>
                    <ul class="d-flex flex-wrap align-items-center m-0 p-0">
                        <li
                            v-for="(social, i) of this.$props.partnerData.socialList"
                            :key="'social_' + i"
                            class="me-3 mb-10"
                        >
                            <a :href="social.url" class="d-flex align-items-center fs-4 text-black crm-underline" >
                                <img :src="require(`@/assets/img/${social.icon}.png`)" alt="social.title" width="40" height="40" class="me-10">
                                {{social.title}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--контент конец-->
        <!--футер-->
        <div v-if="isInfoOpened">
            <div 
                class="d-flex align-items-center px-20 py-10"
                style="height: 54px;"
            >
                <img :src="require('@/assets/icons/staff-icon.svg')" alt="Отключить" width="24" height="24" class="me-10">
                <span 
                    class="fs-4 text-font-primary me-auto"
                    :class="!isStaffListOpened ? 'crm-underline' : null"
                    @click="isStaffListOpened = true"
                    style="cursor: pointer"
                >
                    Сотрудники партнера
                </span>
                
                <button
                    v-if="isStaffListOpened"
                    type="button" 
                    class="btn bg-light-gray text-secondary border border-brd-primary rounded-3 font-semi fw-semi"
                    @click="isStaffListOpened = false"
                >
                    Свернуть
                </button>
            </div>

            <div 
                class="p-10 bg-light-gray border-top border-brd-primary"
                v-if="isStaffListOpened"
            >
                <EmployeeShortList 
                    :employees="this.$props.partnerData.staffList"
                />
            </div>
        </div>
        <!--футер конец-->

        <ConfirmModal
            :id="this.$props.partnerData.id"
            :title="this.confirmModalState.title"
            :text="this.confirmModalState.text"
            :recipient="this.confirmModalState.recipient"
            :fromSection="this.confirmModalState.fromSection"
            :btnText="this.confirmModalState.btnText"
        />
        
        <PartnerEditModal
            :modalType="'editPartner'"
            :partnerData="this.$props.partnerData"
        />

        <PartnerShortInfoModal
            :partnerInfo="this.$props.partnerData"
        />
    </div>
</template>

<script>
    import EmployeeShortList from '@/components/employees/EmployeeShortList'
    import ConfirmModal from '@/components/modals/ConfirmModal'
    import PartnerEditModal from '@/components/modals/PartnerEditModal'
    import PartnerShortInfoModal from '@/components/modals/PartnerShortInfoModal'

    export default {
        name: 'PartnerCard',

        components: {
            EmployeeShortList,
            ConfirmModal,
            PartnerEditModal,
            PartnerShortInfoModal
        },

        props: { 
            partnerData: { type: Object }
        },

        data() {
            return {
                isStaffListOpened: false,
                isInfoOpened: false,
                confirmModalState: {
                    title: '',
                    text: '',
                    recipient: '',
                    fromSection: 'partnerCard',
                    btnText: ''
                }
            }
        },

        methods: {
            fillConfirmModalState(str) {
                if(str === 'turnPartnerOff') {
                    this.confirmModalState.title = "Отключение партнера"
                    this.confirmModalState.text = "Вы действительно хотите отключить партнера?"
                    this.confirmModalState.recipient = this.$props.partnerData.name
                    this.confirmModalState.btnText = "Отключить"
                }

                if(str === 'turnPartnerOn') {
                    this.confirmModalState.title = "Включение партнера"
                    this.confirmModalState.text = "Вы действительно хотите включить партнера?"
                    this.confirmModalState.recipient = this.$props.partnerData.name
                    this.confirmModalState.btnText = "Включить"
                }

                if(str === 'deletePartner') {
                    this.confirmModalState.title = "Удаление партнера"
                    this.confirmModalState.text = "Вы действительно хотите удалить партнера?"
                    this.confirmModalState.recipient = this.$props.partnerData.name
                    this.confirmModalState.btnText = "Удалить"
                }
            }
        }
    }
</script>
