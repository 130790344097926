<template>
    <div 
        class="modal fade" :id="this.$props.modalType === 'addEmployeer' ? this.$props.modalType : this.$props.modalType + this.$props.employeerData.id" 
        tabindex="-1" aria-labelledby="Действие над сотрудником" aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content rounded-1 p-20">
                <div class="modal-header d-flex align-items-center border-0 p-0 pb-20">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
                        {{this.$props.modalType === 'addEmployeer' ? 'Новый сотрудник' : 'Редактирование сотрудника'}}
                    </span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                    ></button>
                </div>

                <div class="modal-body border-0 p-0 mb-3 rounded-0">
                    <!--tabs-->
                    <div class="border-bottom border-brd-primary pb-20">
                        <ul class="d-flex flex-column flex-sm-row flex-sm-wrap align-items-start align-items-sm-center m-0 p-0">
                            <li class="nav-item d-flex align-items-center me-20 mb-10 mb-sm-0">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    @click="this.tabNum = 1"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 1 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/user-card-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 1"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/user-card-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 1"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 1 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Общая инфомация
                                    </span>
                                </button>
                            </li>

                            <li class="nav-item d-flex align-items-center me-20">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 2"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 2 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/blank-gray-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 2"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/blank-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 2"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 2 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Документы
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--end tabs-->

                    <div class="pt-20" v-if="tabNum === 1">
                        <InputSelect
                            :inputLabel="'Компания партнер'"
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_company-partner'"
                            :inputName="'employeer_company-partner'"
                            :inputPlaceholder="'Не выбрана'"
                            :inputOptions="{
                                1: 'Вариант 1'
                            }"
                            :inputClass="'rounded-1 py-3'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_fullName'"
                            :inputName="'employeer_fullName'"
                            :inputLabel="'Ф.И.О.'"
                            :inputPlaceholder="this.employeer.name"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_email'"
                            :inputName="'employeer_email'"
                            :inputLabel="'Эл. почта'"
                            :inputPlaceholder="this.employeer.email"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />                       

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_phone'"
                            :inputName="'employeer_phone'"
                            :inputLabel="'Телефон'"
                            :inputPlaceholder="this.employeer.phone"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_rank'"
                            :inputName="'employeer_rank'"
                            :inputLabel="'Должность'"
                            :inputPlaceholder="this.employeer.rank"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.employeer.id + 'employeer_birthday'"
                            :inputName="'employeer_birthday'"
                            :inputLabel="'День рождения'"
                            :inputPlaceholder="this.employeer.birthDay"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <Switcher
                            :inputId="this.employeer.id + 'employeer_isBirthdayRemind'"
                            :inputName="'employeer_isBirthdayRemind'"
                            :inputLabel="'Напоминать о дне рождения'"
                            :inputLabelClass="'form-check-label text-font-secondary'"
                            :inputClass="'p-0'"
                            :inputWrapClass="'mb-3'"
                        />

                        <div class="mb-3">
                            <span class="d-block mb-3">Социальные сети:</span>
                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.employeer.id + 'employeer_instagramm'"
                                :inputName="'employeer_instagramm'"
                                :inputLabel="'Instagramm'"
                                :inputPlaceholder="this.employeer.instaAcc"
                                :inputClass="'rounded-1'"
                                :inputWrapClass="'mb-3'"
                            />

                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.employeer.id + 'employeer_vKontakte'"
                                :inputName="'employeer_vKontakte'"
                                :inputLabel="'вКонтакте'"
                                :inputPlaceholder="this.employeer.vkAcc"
                                :inputClass="'rounded-1'"
                                :inputWrapClass="'mb-3'"
                            />

                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.employeer.id + 'employeer_telegram'"
                                :inputName="'employeer_telegram'"
                                :inputLabel="'Телеграм'"
                                :inputPlaceholder="this.employeer.tgAcc"
                                :inputClass="'rounded-1'"
                            />
                        </div>
                    </div>

                    <div class="pt-20" v-if="tabNum === 2">
                        <div class="mb-3">
                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.employeer.id + 'employeer_docName'"
                                :inputName="'employeer_docName'"
                                :inputLabel="'Название документа'"
                                :inputClass="'rounded-1'"
                                :inputWrapClass="'mb-3'"
                            />
                        </div>

                        <div class="mb-2">
                            <label for="formFile" class="form-label bg-light-gray px-20 py-3 rounded-3 border border-brd-primary">
                                <img :src="require('@/assets/icons/staple-icon.svg')" alt="Прикрепить файл" width="18" height="18" class="me-10">
                                <span class="text-font-secondary font-semi fw-semi">Выберите файл</span>
                            </label>
                            <input class="form-control bg-light-gray visually-hidden" type="file" id="formFile">
                        </div>

                        <div class="d-flex align-items-center mb-3">
                            <img :src="require('@/assets/icons/blank-icon.svg')" alt="Документ" width="18" height="18" class="me-10">

                            <span class="me-10 fs-4">КП Сокращенное.docx</span>

                            <button type="button" class="btn">
                                <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                            </button>
                        </div>

                        <div class="d-flex align-items-center">
                            <button 
                                class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
                                style="min-width: 150px;"
                            >
                                <span
                                    class=" d-flex align-items-center justify-content-center 
                                            bg-primary rounded-circle me-1
                                        "
                                    style="width: 50px; height: 50px;"
                                >
                                    <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                                </span>
                                <span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить файл</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-footer border-0 p-0">
                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 m-0 py-3 text-white fs-3" 
                        data-bs-dismiss="modal"
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from '@/components/inputs/InputText'
    import InputSelect from '@/components/inputs/InputSelect'
    import Switcher from '@/components/inputs/Switcher'

    export default {
        name: 'EmployeerEditModal',

        components: {
            InputText,
            InputSelect,
            Switcher
        },

        props: {
            modalType:      { type: String },
            employeerData:  { type: Object }
        },

        data() {
            return {
                tabNum: 1,
                employeer: (typeof this.employeerData !== "undefined") ? this.employeerData : {}
            }
        }
    }
</script>