<template>
    <div class="modal fade" :id="this.$props.modalType === 'addPartner' ? this.$props.modalType : this.$props.modalType + this.$props.partnerData.id" tabindex="-1" aria-labelledby="Подтверждение действия" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content rounded-1">
                <div class="modal-header d-flex align-items-center border-0 p-20 pb-3">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
                        {{this.$props.modalType === 'addPartner' ? 'Новый партнер' : this.$props.modalType === 'editPartner' ? 'Редактирование партнера' : null}}
                    </span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                    ></button>
                </div>

                <div class="modal-body border-0 px-20 pt-1 rounded-0">
                    <!--tabs-->
                    <div class="border-bottom border-brd-primary">
                        <ul 
                            class="d-flex flex-column flex-sm-row flex-sm-wrap align-items-start align-items-sm-center m-0 p-0"
                        >
                            <li 
                                class="nav-item d-flex align-items-center mb-20 w-50"
                                style="min-width: 180px;"
                            >
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 1"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 1 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/company-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 1"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/company-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 1"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="this.tabNum === 1 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        О компании
                                    </span>
                                </button>
                            </li>

                            <li 
                                class="nav-item d-flex align-items-center mb-20 w-50"
                                style="min-width: 180px;"
                            >
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 2"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 2 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/manager-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 2"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/manager-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 2"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="this.tabNum === 2 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Руководитель
                                    </span>
                                </button>
                            </li>

                            <li 
                                class="nav-item d-flex align-items-center mb-20 w-50"
                                style="min-width: 180px;"
                            >
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 3"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 3 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/blank-gray-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 3"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/blank-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 3"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="this.tabNum === 3 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Документы
                                    </span>
                                </button>
                            </li>

                            <li 
                                class="nav-item d-flex align-items-center mb-20 w-50"
                                style="min-width: 180px;"
                            >
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 4"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 4 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/requisitions-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 4"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/requisitions-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 4"
                                        >
                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="this.tabNum === 4 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Реквизиты
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--end tabs-->

                    <!--content-->
                    <div class="pt-20" v-if="tabNum === 1">
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_companyName'"
                            :inputName="'partner_companyName'"
                            :inputLabel="'Наименование'"
                            :inputPlaceholder="this.partner.title"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_companyAddress'"
                            :inputName="'partner_companyAddress'"
                            :inputLabel="'Адрес отделения'"
                            :inputPlaceholder="this.partner.address"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_companyEstate'"
                            :inputName="'partner_companyEstate'"
                            :inputLabel="'Жилой комплекс'"
                            :inputPlaceholder="this.partner.estate"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                    </div>

                    <div class="pt-20" v-if="tabNum === 2">
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_fullName'"
                            :inputName="'partner_fullName'"
                            :inputLabel="'Ф.И.О.'"
                            :inputPlaceholder="this.partner.name"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_email'"
                            :inputName="'partner_email'"
                            :inputLabel="'Эл. почта'"
                            :inputPlaceholder="this.partner.email"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />                       

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_phone'"
                            :inputName="'partner_phone'"
                            :inputLabel="'Телефон'"
                            :inputPlaceholder="this.partner.phone"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_rank'"
                            :inputName="'partner_rank'"
                            :inputLabel="'Должность'"
                            :inputPlaceholder="this.partner.rank"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_birthday'"
                            :inputName="'partner_birthday'"
                            :inputLabel="'День рождения'"
                            :inputPlaceholder="this.partner.birthDay"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <Switcher
                            :inputId="this.partner.id + 'partner_isBirthdayRemind'"
                            :inputName="'partner_isBirthdayRemind'"
                            :inputLabel="'Напоминать о дне рождения'"
                            :inputLabelClass="'form-check-label text-font-secondary'"
                            :inputClass="'p-0'"
                            :inputWrapClass="'mb-3'"
                        />

                        <div class="mb-3">
                            <span class="d-block mb-3">Социальные сети:</span>
                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.partner.id + 'partner_instagramm'"
                                :inputName="'partner_instagramm'"
                                :inputLabel="'Instagramm'"
                                :inputPlaceholder="this.partner.instaAcc"
                                :inputClass="'rounded-1'"
                                :inputWrapClass="'mb-3'"
                            />

                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.partner.id + 'partner_vKontakte'"
                                :inputName="'partner_vKontakte'"
                                :inputLabel="'вКонтакте'"
                                :inputPlaceholder="this.partner.vkAcc"
                                :inputClass="'rounded-1'"
                                :inputWrapClass="'mb-3'"
                            />

                            <InputText 
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="this.partner.id + 'partner_telegram'"
                                :inputName="'partner_telegram'"
                                :inputLabel="'Телеграм'"
                                :inputPlaceholder="this.partner.tgAcc"
                                :inputClass="'rounded-1'"
                            />
                        </div>
                    </div>

                    <div class="pt-20" v-if="tabNum === 3">
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'partner_docName'"
                            :inputName="'partner_docName'"
                            :inputLabel="'Название документа'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <div class="mb-2">
                            <label for="formFile" class="form-label bg-light-gray px-20 py-3 rounded-3 border border-brd-primary">
                                <img :src="require('@/assets/icons/staple-icon.svg')" alt="Прикрепить файл" width="18" height="18" class="me-10">
                                <span class="text-font-secondary font-semi fw-semi">Выберите файл</span>
                            </label>
                            <input class="form-control bg-light-gray visually-hidden" type="file" id="formFile">
                        </div>

                        <div class="d-flex align-items-center mb-3">
                            <img :src="require('@/assets/icons/blank-icon.svg')" alt="Документ" width="18" height="18" class="me-10">

                            <span class="me-10 fs-4">КП Сокращенное.docx</span>

                            <button type="button" class="btn">
                                <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                            </button>
                        </div>

                        <div class="d-flex align-items-center">
                            <button 
                                class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
                                style="min-width: 150px;"
                            >
                                <span
                                    class=" d-flex align-items-center justify-content-center 
                                            bg-primary rounded-circle me-1
                                        "
                                    style="width: 50px; height: 50px;"
                                >
                                    <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                                </span>
                                <span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить файл</span>
                            </button>
                        </div>
                    </div>

                    <div class="pt-20" v-if="tabNum === 4">
                        <TextArea
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.partner.id + 'addClient_bankReq'"
                            :inputName="'addClient_bankReq'"
                            :inputLabel="'Банковские реквизиты'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />  
                    </div>
                    <!--end content-->
                </div>

                <div class="modal-footer border-0 p-20 pt-0 ">
                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 m-0 py-3 text-white fs-3" 
                        data-bs-dismiss="modal"
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from '@/components/inputs/InputText'
    import Switcher from '@/components/inputs/Switcher'
    import TextArea from '@/components/inputs/TextArea'

    export default {
        name: "PartnerEditModal",

        components: {
            InputText,
            TextArea,
            Switcher
        },
        props: {
            modalType: { type: String },
            partnerData:  { type: Object }
        },

        data() {
            return {
                tabNum: 1,
                partner: (typeof this.partnerData !== "undefined") ? this.partnerData : {}
            }
        }
    }
</script>