<template>
	<div class="partners position-relative overflow-hidden">
        <!-- шапка блока-->
        <div class="p-20 mb-20 bg-white border-bottom border-brd-primary position-relative">
            <div class="page-header">
                <h1 class="page-title">Партнеры</h1>

                <div>
                    <div class="">
                        <div class="d-flex text-nowrap-mask scroller scroller-x pb-3 mb-2 mb-sm-0" role="tablist">
                            <NavTabs :navItems="this.tabItems"/>
                        </div>

                        <div 
                            class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center mb-10"
                        >
                            <button 
                                class="btn d-flex align-items-center p-0 mb-2 mb-sm-0 me-20 rounded-pill"
                                data-bs-toggle="modal" data-bs-target="#addPartner"
                            >
                                <span
                                    class=" d-flex align-items-center justify-content-center 
                                            bg-primary rounded-circle me-1
                                        "
                                    style="width: 50px; height: 50px;"
                                >
                                    <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                                </span>
                                <span class="text-font-dark fw-semi font-semi fs-4 px-2">Новый партнер</span>
                            </button>

                            <button 
                                class="btn d-flex align-items-center p-0 mb-2 mb-sm-0 me-20 rounded-pill"
                                data-bs-toggle="modal" data-bs-target="#addEmployeer"
                            >
                                <span
                                    class=" d-flex align-items-center justify-content-center 
                                            bg-primary rounded-circle me-1
                                        "
                                    style="width: 50px; height: 50px;"
                                >
                                    <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                                </span>
                                <span class="text-font-dark fw-semi font-semi fs-4 px-2">Новый сотрудник</span>
                            </button>
                        </div>

                        <div class="input-group">
                            <input type="text" class="form-control rounded-end rounded-1" placeholder="Имя, Название, Email или Телефон" aria-label="Поиск" aria-describedby="button-addon2">
                            
                            <button type="button" aria-label="Поиск" id="button-addon2" class="crm-btn btn bg-primary me-10 rounded-start rounded-1">
                                <img :src="require('@/assets/icons/search-white-icon.svg')" alt="Поиск" width="18" height="18">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- шапка блока конец-->

        <!--контент-->
        <div class="tab-content">
            <div 
                class="tab-pane fade show active"
                id="active" 
                role="tabpanel" 
                aria-labelledby="Вкладка активные партнеры"
            >
                <PartnerList 
                    :partnerList="this.activePartners"
                />
            </div>

            <div 
                class="tab-pane fade" 
                id="passive" 
                role="tabpanel" 
                aria-labelledby="Вкладка не активные партнеры"
            >
                <PartnerList 
                    :partnerList="this.passivePartners"
                />
            </div>
        </div>
        <!--контент конец-->

        <!--modal addEmployeer-->
        <EmployeerEditModal 
            :modalType="'addEmployeer'"
        />

        <!--modal addPartner-->
        <PartnerEditModal
            :modalType="'addPartner'"
        />
	</div>
</template>

<script>
    import CommonService from '../services/CommonService'
    import NavTabs from '@/components/common/NavTabs'
    import PartnerList from '@/components/partners/PartnerList'
    import EmployeerEditModal from '@/components/modals/EmployeerEditModal'
    import PartnerEditModal from '@/components/modals/PartnerEditModal'


    export default {
        name: "partners",

        components: {
            NavTabs,
            PartnerList,
            EmployeerEditModal,
            PartnerEditModal
        },

        data() {
            return { 
                tabItems: [
                    {label: "Активные", title: "Активные", count: 121321, id: 'active-tab', data_bs_toggle:'tab', data_bs_target: '#active', active: true, href: '#', action: () => console.log('Tab 1 clicked!')},
                    {label: "Не активные", title: "Не активные", count: 32313, id: 'passive-tab', data_bs_toggle:'tab', data_bs_target: '#passive', active: false, href: '#', action: () => console.log('Tab 2 clicked!')},
                ],

                activePartners: [
                    {
                        id: 1,
                        status: 'Партнер',
                        published_at: '09.03.2021 11:37',
                        isActive: true,
                        title: 'Тестовый партнер',
                        manager: 'Петров Иван Иванович',
                        rank: 'Менеджер ОП',
                        birthDay: '02.03.1005',
                        isRemindBirthday: true,
                        phone: '+79219942379',
                        email: 'test@example.com',
                        instaAcc: 'instagramm account',
                        vkAcc: 'vk account',
                        tgAcc: 'tg account',
                        requisitions: 'тут реквизиты',

                        socialList: [
                            {
                                title: 'Вконтакте',
                                icon: 'vk-icon',
                                url: '#'
                            },
                            {
                                title: 'Instagramm',
                                icon: 'insta-icon',
                                url: '#'
                            },
                            {
                                title: 'Telegram',
                                icon: 'tg-icon',
                                url: '#'
                            },
                        ],

                        name: 'Тестовый партнер',
                        address: 'Московский проспект',

                        estate: 'ЖК Речной',

                        clients: ['test'],

                        staffList: [
                            {
                                id: 2,
                                isActive: true,
                                published_at: '10.03.2021 10:35',
                                birthDay: '05.05.1989',
                                isRemindBirthday: true,
                                name: 'Филатова Юлия',
                                role: 'Сотрудник',
                                rank: 'Менеджер по рекламе',
                                phone: '+79219942379',
                                email: 'test@example.com',
                                instaAcc: 'instagramm account',
                                vkAcc: 'vk account',
                                tgAcc: 'tg account',

                                clients: ['test']
                            },

                            {
                                id: 3,
                                published_at: '10.03.2021 10:35',
                                birthDay: '05.05.1989',
                                isRemindBirthday: true,
                                name: 'Мария',
                                role: 'Менеджер',
                                rank: 'Менеджер по рекламе',
                                phone: '+79219942379',
                                email: 'test@example.com',
                                instaAcc: 'instagramm account',
                                vkAcc: 'vk account',
                                tgAcc: 'tg account',

                                isActive: true,

                                clients: ['test']
                            },
                        ],
                    },
                ],

                passivePartners: [
                {
                        id: 4,
                        status: 'Партнер',
                        published_at: '09.03.2021 11:37',
                        isActive: false,

                        name: 'Тестовый партнер',
                        address: 'Московский проспект',

                        estate: 'ЖК Морской',

                        clients: ['test'],

                        staffList: [
                            {
                                id: 123467800011122,
                                published_at: '10.03.2021 10:35',
                                name: 'Филатова Юлия',
                                role: 'Сотрудник',
                                rank: 'Менеджер по рекламе',
                                phone: '+79219942379',
                                email: 'test@example.com',
                                isActive: true,

                                clients: ['test']
                            },

                            {
                                id: 123,
                                published_at: '10.03.2021 10:35',
                                name: 'Мария',
                                role: 'Секретарь',
                                rank: 'Менеджер по рекламе',
                                phone: '+79219942379',
                                email: 'test@example.com',
                                isActive: false,

                                clients: ['test','test']
                            }
                        ],

                        manager: 'Петров Иван Иванович',
                        phone: '+79219942379',
                        email: 'test@example.com',

                        socialList: [
                            {
                                title: 'Вконтакте',
                                icon: 'vk-icon',
                                url: '#'
                            },
                            {
                                title: 'Instagramm',
                                icon: 'insta-icon',
                                url: '#'
                            },
                            {
                                title: 'Telegram',
                                icon: 'tg-icon',
                                url: '#'
                            },
                        ],
                    },
                ]
            };
        },

        methods: {

        },
        mounted() {
            CommonService.setPageTitle(this.title);
        }
    };
</script>