<template>
    <div class="d-flex">
        <div class="me-auto " style="max-width: 80%;">

            <div class="d-flex flex-wrap mb-3">
                <span class="text-secondary me-20 fs-5">{{this.$props.employeer.role}}</span>
                <span class="text-secondary me-20 fs-5">Добавлен: {{this.$props.employeer.published_at}}</span>
            </div>

            <div class="mb-2">
                <button
                    v-if="!this.$props.employeer.isActive"
                    type="button" 
                    class="
                            btn btn-outline-raspberry rounded-1
                            fs-5 font-semi fw-semi p-10 px-3
                        "
                >
                        Отключен
                </button>
            </div>

            <div class="d-flex flex-column mb-3">
                <a 
                    href="#" 
                    class="crm-underline text-black fs-3 fw-semi font-semi" 
                    style="max-width: 300px; cursor: pointer;"
                    data-bs-toggle="modal" :data-bs-target="'#employeerShortInfo' + this.$props.employeer.id"
                >
                    {{this.$props.employeer.name}}
                </a>

                <span
                    class="fst-italic text-font-secondary fs-5"
                >
                    {{this.$props.employeer.rank}}
                </span>
            </div>

            <div class="d-flex flex-column mb-3">
                <span class="text-font-secondary fs-5">Клиенты</span>

                <a 
                    href="#" 
                    class="text-black crm-underline"
                >
                    {{this.$props.employeer.clients.length}}
                </a>
            </div>

            <div class="d-flex flex-column mb-3">
                <span class="text-font-secondary fs-5">Телефон</span>
                <span>{{this.$props.employeer.phone}}</span>
            </div>

            <div class="d-flex flex-column">
                <span class="text-font-secondary fs-5">Эл. почта</span>
                
                <a 
                    :href="'mailto:' + this.$props.employeer.email" 
                    class="text-black crm-underline"
                >
                    {{this.$props.employeer.email}}
                </a>
            </div>
        </div>
        
        <div class="d-flex flex-column">
            <button 
                type="button" class="btn mb-2"
                data-bs-toggle="modal" :data-bs-target="'#editEmployeer' + this.$props.employeer.id"
            >
                <img :src="require('@/assets/icons/options-icon.svg')" alt="Настройка" width="24" height="24">
            </button>

            <button 
                type="button" class="btn mb-2"
                data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.employeer.id"
                @click="fillConfirmModalState('deleteEmployeer')"
            >
                <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
            </button>

            <button
                v-if="!this.$props.employeer.isActive"
                type="button" class="btn mb-2"
                data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.employeer.id"
                @click="fillConfirmModalState('turnEmployeerOn')"
            >
                <img :src="require('@/assets/icons/turnOff-red-icon.svg')" alt="Отключить" width="24" height="24">
            </button>

            <button
                v-else
                type="button" class="btn mb-2"
                data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.employeer.id"
                @click="fillConfirmModalState('turnEmployeerOff')"
            >
                <img :src="require('@/assets/icons/turnOff-icon.svg')" alt="Отключить" width="24" height="24">
            </button>
        </div>

        <ConfirmModal
            :id="this.$props.employeer.id"
            :title="this.confirmModalState.title"
            :text="this.confirmModalState.text"
            :recipient="this.confirmModalState.recipient"
            :fromSection="this.confirmModalState.fromSection"
            :btnText="this.confirmModalState.btnText"
        />

        <EmployeerEditModal
            :modalType="'editEmployeer'"
            :employeerData="this.$props.employeer"
        />

        <EmployeerShortInfoModal 
            :employeerInfo="this.$props.employeer"
        />
    </div>
</template>

<script>
    import ConfirmModal from '@/components/modals/ConfirmModal'
    import EmployeerEditModal from '@/components/modals/EmployeerEditModal'
    import EmployeerShortInfoModal from '@/components/modals/EmployeerShortInfoModal'
    
    export default {
        name: 'EmployeerShortCard',

        components: {
            ConfirmModal,
            EmployeerEditModal,
            EmployeerShortInfoModal
        },

        props: {
            employeer: { type: Object }
        },

        data() {
            return{
                confirmModalState: {
                    title: '',
                    text: '',
                    recipient: '',
                    fromSection: 'employeerShortCard',
                    btnText: ''
                }
            }
        },

        methods: {
            fillConfirmModalState(str) {
                if(str === 'turnEmployeerOff') {
                    this.confirmModalState.title = "Отключение сотрудника"
                    this.confirmModalState.text = "Вы действительно хотите отключить сотрудника?"
                    this.confirmModalState.recipient = this.$props.employeer.name
                    this.confirmModalState.btnText = "Отключить"
                }

                else if(str === 'turnEmployeerOn') {
                    this.confirmModalState.title = "Включение сотрудника"
                    this.confirmModalState.text = "Вы действительно хотите включить сотрудника?"
                    this.confirmModalState.recipient = this.$props.employeer.name
                    this.confirmModalState.btnText = "Включить"
                }

                else if(str === 'deleteEmployeer') {
                    this.confirmModalState.title = "Удаление сотрудника"
                    this.confirmModalState.text = "Вы действительно хотите удалить сотрудника?"
                    this.confirmModalState.recipient = this.$props.employeer.name
                    this.confirmModalState.btnText = "Удалить"
                }
            }
        },

        watch: {
            employeer(newVal,oldVal) {
                console.log(newVal, oldVal)
            }
        }
    }
</script>